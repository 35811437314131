import React, { useState, useEffect } from 'react';
import { headData } from '../mock/data';
import Layout from '../components/Layout/Layout.jsx';
import Title from '../components/Title/Title';
import styled from 'styled-components';
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import Fade from 'react-reveal/Fade';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../style/main.scss';

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${(props) => props.marginbottom};
  margin-top: ${(props) => props.margintop};
`;

const Header = styled.h1`
  text-align: left;
  @media (max-width: 500px) {
    text-align: center;
  }
`;

const PrivacyPolicy = () => {
  const { title, lang, description } = headData;
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const head = document.querySelector('head');
    const script = document.createElement('script');
    script.setAttribute(
      'src',
      '//static.ctctcdn.com/js/signup-form-widget/current/signup-form-widget.min.js',
      'id',
      'signupScript'
    );
    head.appendChild(script);
    setLoading(false);
  }, []);

  if (loading) return null;

  return (
    <Layout paddingbottom buttoncolor border backgroundcolor color>
      <GatsbySeo
        title="Iffert Media | Privacy Policy"
        description={description}
        lang={lang}
        canonical="https://www.iffertmedia.com/"
        openGraph={{
          url: 'https://www.iffertmedia.com/services',
          title: 'Iffert Media | Privacy Policy',
          description:
            'Our social media marketing company offers social media marketing and advertising, and website design to give you the marketing help your conpany deserves! See our services to grow...',
          images: [
            {
              url:
                'https://www.iffertmedia.com/static/ee7180b9430b423ddc2fdbdfe2f76f3f/0dadc/IFFERT-MEDIA.png',
              width: 800,
              height: 600,
              alt: 'Iffert Media',
            },
          ],
          site_name: 'Iffert Media | Iffert Media Services',
        }}
        twitter={{
          cardType: 'summary_large_image',
        }}
      />
      <Title display fontsize title="Privacy Policy" />
      <Fade bottom duration={1000} delay={600} distance="30px">
        <div className="services-wrapper__info">
          <Header>1. Terms</Header>
          <p className="services-wrapper__info-text">
            By accessing this web site, you are agreeing to be bound by these web site Terms and
            Conditions of Use, applicable laws and regulations and their compliance. If you disagree
            with any of the stated terms and conditions, you are prohibited from using or accessing
            this site. The materials contained in this site are secured by relevant copyright and
            trade mark law.
          </p>
          <Header>2. Use License</Header>
          <p className="services-wrapper__info-text">
            - Permission is allowed to temporarily download one duplicate of the materials (data or
            programming) on Iffert Media's site for individual and non-business use only. This is
            the just a permit of license and not an exchange of title, and under this permit you may
            not:
          </p>
          <p className="services-wrapper__info-text">1. modify or copy the materials.</p>
          <p className="services-wrapper__info-text">
            2. use the materials for any commercial use , or for any public presentation (business
            or non-business)
          </p>
          <p className="services-wrapper__info-text">
            3. attempt to decompile or rebuild any product or material contained on Iffert Media's
            site; remove any copyright or other restrictive documentations from the materials; or...
          </p>
          <p className="services-wrapper__info-text">
            4. transfer the materials to someone else or even "mirror" the materials on other
            server. - This permit might consequently be terminated if you disregard any of these
            confinements and may be ended by Iffert Media whenever deemed. After permit termination
            or when your viewing permit is terminated, you must destroy any downloaded materials in
            your ownership whether in electronic or printed form.
          </p>
          <Header>3. Disclaimer</Header>
          <p className="services-wrapper__info-text">
            - The materials on Iffert Media's site are given "as is". Iffert Media makes no
            guarantees, communicated or suggested, and thus renounces and nullifies every single
            other warranties, including without impediment, inferred guarantees or states of
            merchantability, fitness for a specific reason, or non-encroachment of licensed property
            or other infringement of rights. Further, Iffert Media does not warrant or make any
            representations concerning the precision, likely results, or unwavering quality of the
            utilization of the materials on its Internet site or generally identifying with such
            materials or on any destinations connected to this website.
          </p>
          <Header>4. Constraints</Header>
          <p className="services-wrapper__info-text">
            - In no occasion should Iffert Media or its suppliers subject for any harms (counting,
            without constraint, harms for loss of information or benefit, or because of business
            interference,) emerging out of the utilization or powerlessness to utilize the materials
            on Iffert Media's Internet webpage, regardless of the possibility that Iffert Media or a
            Iffert Media approved agent has been told orally or in written of the likelihood of such
            harm. Since a few purviews don't permit constraints on inferred guarantees, or
            impediments of obligation for weighty or coincidental harms, these confinements may not
            make a difference to you.
          </p>
          <Header>5. Amendments and Errata</Header>
          <p className="services-wrapper__info-text">
            - The materials showing up on Iffert Media's site could incorporate typographical, or
            photographic mistakes. Iffert Media does not warrant that any of the materials on its
            site are exact, finished, or current. Iffert Media may roll out improvements to the
            materials contained on its site whenever without notification. Iffert Media does not,
            then again, make any dedication to update the materials.
          </p>
          <Header>6. Links</Header>
          <p className="services-wrapper__info-text">
            - Iffert Media has not checked on the majority of the websites or links connected to its
            website and is not in charge of the substance of any such connected webpage. The
            incorporation of any connection does not infer support by Iffert Media of the site.
            Utilization of any such connected site is at the user's own risk.
          </p>
          <Header>7. Site Terms of Use Modifications</Header>
          <p className="services-wrapper__info-text">
            - Iffert Media may update these terms of utilization for its website whenever without
            notification. By utilizing this site you are consenting to be bound by the then current
            form of these Terms and Conditions of Use.
          </p>
          <Header>8. Governing Law</Header>
          <p className="services-wrapper__info-text">
            - Any case identifying with Iffert Media's site should be administered by the laws of
            the country of United States Iffert Media State without respect to its contention of law
            provisions. General Terms and Conditions applicable to Use of a Web Site.
          </p>
          <Header>Privacy Policy</Header>
          <p className="services-wrapper__info-text">
            - Your privacy is critical to us. Likewise, we have built up this Policy with the end
            goal you should see how we gather, utilize, impart and reveal and make utilization of
            individual data. The following blueprints our privacy policy.
          </p>
          <p className="services-wrapper__info-text">
            1. Before or at the time of collecting personal information, we will identify the
            purposes for which information is being collected.
          </p>
          <p className="services-wrapper__info-text">
            2. We will gather and utilization of individual data singularly with the target of
            satisfying those reasons indicated by us and for other good purposes, unless we get the
            assent of the individual concerned or as required by law.
          </p>
          <p className="services-wrapper__info-text">
            3. We will just hold individual data the length of essential for the satisfaction of
            those reasons.
          </p>
          <p className="services-wrapper__info-text">
            4. We will gather individual data by legal and reasonable means and, where fitting, with
            the information or assent of the individual concerned.
          </p>
          <p className="services-wrapper__info-text">
            5. Personal information ought to be important to the reasons for which it is to be
            utilized, and, to the degree essential for those reasons, ought to be exact, finished,
            and updated.
          </p>
          <p className="services-wrapper__info-text">
            6. We will protect individual data by security shields against misfortune or burglary,
            and also unapproved access, divulgence, duplicating, use or alteration.
          </p>
          <p className="services-wrapper__info-text">
            7. We will promptly provide customers with access to our policies and procedures for the
            administration of individual data.
          </p>
          <p className="services-wrapper__info-text">
            We are focused on leading our business as per these standards with a specific end goal
            to guarantee that the privacy of individual data is secure and maintained.
          </p>
        </div>
        <Column>
          <a
            href="/"
            className="cta-btn cta-btn--about-page"
            style={{ color: '#000', width: '50%' }}
          >
            Return Home
          </a>
        </Column>
      </Fade>
    </Layout>
  );
};

export default PrivacyPolicy;
